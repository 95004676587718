<template>
  <q-page class="flex flex-center bg-primary auth">
    <q-card class="my-card">
      <q-form @submit="updatePassword(form)">
        <q-card
          class="register-account"
          :bordered="!$q.platform.is.mobile"
          flat
          :class="$q.platform.is.mobile ? 'q-pa-sm  transparent' : 'q-pa-md'"
          :style="$q.platform.is.mobile ? `width: ${$q.screen.width - 10}px` : 'width: 512px'"
        >
          <q-card-section class="text-center"></q-card-section>

          <q-card-section class="text-center q-pt-md q-pb-none">
            <q-img
              :src="require('@/assets/images/logo_color.png')"
              spinner-color="white"
              width="168px"
              spinner-size="82px"
            />
          </q-card-section>
          <q-card-section class="q-col-gutter-md">
            <q-input
              clearable
              clear-icon="close"
              ref="email"
              outlined
              type="email"
              label="Email"
              v-model="form.email"
              lazy-rules
              :rules="[isRequired, isEmail]"
            >
            </q-input>
            <q-input
              clearable
              clear-icon="close"
              ref="password"
              outlined
              :label="$t('password')"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              lazy-rules
              :rules="[isRequired]"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  no-caps
                  :label="showPassword ? $t('hide') : $t('show')"
                  @click="showPassword = !showPassword"
                />
              </template>
              <template v-slot:hint> </template>
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                <b>Sua senha deve conter no mínimo:</b>
                <ul>
                  <li>8 Caracteres</li>
                  <li>1 Letra Maiúscula</li>
                  <li>1 Letra minúscula</li>
                  <li>1 Número</li>
                  <li>1 Caractere Especial</li>
                </ul>
              </q-tooltip>
            </q-input>
            <q-input
              clearable
              clear-icon="close"
              ref="checkPassword"
              outlined
              :label="$t('confirm_pass')"
              v-model="form.password_confirmation"
              :type="showCheckPassword ? 'text' : 'password'"
              lazy-rules
              :rules="[isRequired]"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  no-caps
                  :label="showCheckPassword ? $t('hide') : $t('show')"
                  @click="showCheckPassword = !showCheckPassword"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-section class="q-px-md row justify-between q-pt-md">
            <q-btn
              unelevated
              outline
              no-caps
              color="primary"
              class="q-py-sm q-px-lg"
              :label="$t('cancel')"
              to="/auth/login"
            />
            <q-btn unelevated color="primary" no-caps class="q-py-sm q-px-lg" label="Alterar" type="submit" />
          </q-card-section>
        </q-card>
      </q-form>
    </q-card>
  </q-page>
</template>
<script>
export default {
  name: 'Auth',
  mixins: [],
  props: {
    hash: {
      type: String,
      require: true,
      default: null
    }
  },
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
        email: ''
      },
      //   hash_exemplo:'
      //   eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0NTAiLCJqdGkiOiIwOWMyMGQ2YWZjZGFhZmFhZGUzMGU5M2U0Y2JhZjRlMGY3MzgwYTNlYWMzYzNlZGVjYzAwNzFiNmE4YWE5NmIwY2NiZTRjZGFjYWIwNTcwOCIsImlhdCI6MTY0OTc5ODAxMCwibmJmIjoxNjQ5Nzk4MDEwLCJleHAiOjE2NDk4ODQ0MTAsInN1YiI6IjE0OCIsInNjb3BlcyI6WyJlZGl0X2FmZmlsaWF0ZSIsImVkaXRfYWZmaWxpYXRlX3BheW1lbnQiLCJkZWxldGVfc2l0ZSIsImNyZWF0ZV9zaXRlIiwiZWRpdF9zaXRlX3N0YXR1cyIsImNyZWF0ZV9jYW1wYWlnbl9zaXRlIiwiZ2V0X2Rhc2hib2FyZF9hZmlsaWFkbyIsImdldF9wcm9maWxlX2FmaWxpYWRvIiwiZ2V0X3NpdGVMaXN0X2FmaWxpYWRvIiwiZ2V0X2NhbXBhaWduTGlzdF9hZmlsaWFkbyIsImdldF9jcmVhdGl2ZUxpc3RfYWZpbGlhZG8iLCJjcmVhdGVfcGF5bWVudF9pbmZvIiwiY3JlYXRpdmVfYWR2YW5jZWRfc2VhcmNoIiwic2VhcmNoX2NhbXBhaWduc19hZmZpbGlhdGUiLCJzZWFyY2hfY2FtcGFpZ25fYnlfY29taXNzaW9uIiwiZ2V0X3JlcG9ydF9hZmZpbGlhdGVfcGFnZSIsImFkbWluX2NvbWlzc2lvbl9zZWFyY2giLCJhZmZpbGlhdGVfY29taXNzaW9uX3NlYXJjaCIsImFkbWluX2dldF9zaG9ydGxpbmsiLCJhZmlsaWF0ZV9wb3N0YmFjayIsImFmaWxpYXRlX3Bvc3RiYWNrX2Nsb3NlZHRhZyIsImNyZWF0ZV9wb3N0YmFja19zY29wZV9jb250cm9sbGVyIiwiY3JlYXRlX3BheWFmZmlsaWF0ZSIsInRva2VuX2hhc2hfYWZmIiwiY3JlYXRlX29wdGluX2VtYWlsX3Njb3BlIiwiZ2V0X2RlZXBsaW5rX2RvbWFpbiIsImFkbWluX2ZpbmFuY2VfYWZmaWxpYXRlX2V4dHJhY3QiXX0.uuk7C5_js1BbRvpGhbeHjEXNUBNPXAFhuncfjqPLsd8PNyhem9uDgkB13KJLkkW9mStnCUoNU_4mEPjuLmuHXiRGKoPN-Tfq7EHVZJ34FUUv3pLyVSB6i2J3D2GOjG33RwTz3kgBjLBO7zI224EeM2Cz04Xd6VwbZ_BbF8taQJKc3dneGGoAregz2J6DuihPMD3a8eqUO-qSwZPTfYxuQI5yF51HXSkTxCREPIglbz2KwQft2ycm3o6_75O9OQ4bWYK_2r4VF4rqIfjrrT3Nh2Oevniw6LT_HC7LlzylMRstBPiwFeFfd03-WbGv5jI7VAOz4Uvq6lgX8DxCtsu9OfN_476EMsbDv5hNiiA03RzGDAbf7246bKLaQl3Y11PL5fylhcHnQ_EfYskGFWK1bR85e3Lgl9hUTlLc_EALXFaYr7KEdtI7wrrZq3E1U_KN_CZzqqwLLwG1oaXgSs09H2JYB7GPQrbmu9bzlHKn4JLJb5wu29FQ0KlFxiDd883Bq0K27xy64rBpG8D-7B_FFOxnNoz05t-K7m80WFBJ0hORoLkZ8IpaOnqdWiPTCI-lZpS0RyJAd5cZL9FlrzvYmgYvPtUPAKzJEwkKNCv7NeSEwhcTP2sqqs_FYawDfdEyHyuM05bhwSHVbzc4bF9ZzJpejwcL_UlsfIkYnNMeJmo'
      showPassword: false,
      showCheckPassword: false
    };
  },
  methods: {
    async updatePassword(form) {
      form['token'] = this.hash;
      this.onLoading(true);
      try {
        const { status } = await this.$http.put('/edit/user/resetpassword', form);
        if (status === 200 || status === 201) {
          this.successNotify('Senha alterada com sucesso!');
          this.$router.push({ name: 'Auth' });
        }
      } catch (error) {
        //this.errorNotify(error);
        this.showInfoDialog(
          'Sua senha deve conter no mínimo:',
          '<ul><li>8 Caracteres</li><li>1 Letra Maiúscula</li><li>1 Letra minúscula</li><li>1 Número</li><li>1 Caractere Especial</li></ul>'
        );
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style>
.auth .q-select__dropdown-icon {
  color: white;
}
</style>
